<template lang="html">
  <GradientBackLayout
    class="MembersPageV2"
    :title="$filters.translate('my_community')"
    :shadowText="$filters.translate('community')"
  >
  <div class="LoadingIcon" v-if="loadingTags">
    <i class="fas fa-spinner fa-spin"></i>
  </div> 
    <BoxShadow class="MembersPageV2--Box">
      <div class="MembersPageV2__Tabs">
        <div
          class="MembersPageV2__Tabs--tab"
          :class="{ active: membersTab == 0 }"
          v-on:click="membersTab = 0"
        >
        {{$t('members_text')}}
        </div>
        <div
          class="MembersPageV2__Tabs--tab"
          :class="{ active: membersTab == 1 }"
          v-on:click="membersTab = 1"
        >
        {{$t('statistics')}}
        </div>
        <div
          class="MembersPageV2__Tabs--tab"
          v-if="isClient"
          :class="{ active: membersTab == 2 }"
          v-on:click="membersTab = 2"
        >
        {{$t('ranking')}}
        </div>
      </div>
      <div v-show="membersTab == 0">
        <div class="MembersPageV2--Header">
          <div>
            <CommunityButton v-on:click="showDeleteUserModal = true">{{
              $t("members_delete_from_email")
            }}</CommunityButton>
            <CommunityButton v-on:click="goToEditLabs()">{{
              $t("labs_management2")
            }}</CommunityButton>
            <CommunityButton
              v-on:click="fetchMembersExcel()"
              v-if="isCommunityAdmin"
              >{{ $t("download_excel") }}</CommunityButton
            >
            <CommunityButton
              style="display: none"
              v-on:click="showLoadUsersModal = true"
              v-if="isCommunityMainAdmin"
              >{{$t('members_load_text')}}</CommunityButton
            >
            <CommunityButton
              v-on:click="goToUsersLoad()"
              v-if="isCommunityMainAdmin || isModerator"
              >{{$t('members_load_text')}}</CommunityButton
            >
          </div>
          <CommunityButton v-on:click="showMemberEdition()"
            >{{$t('members_add_new')}}</CommunityButton
          >
        </div>
        <div 
          class="MembersPageV2--TagsList"
          v-if="showTagsList">
          <div class="close">
            <div class="MembersPageV2--Header no-margin">
              <div>
                <CommunityButton v-on:click="showTagListInfo()">?</CommunityButton>
                <CommunityButton v-on:click="showAllTags()">X</CommunityButton>
              </div>
            </div>
          </div>
          <b>{{$t('community_tags_list')}}</b><br/>
          <div class="MembersPageV2--Tags">
            <div class="single-tags">
              <Tag
                :name="ct.name"
                :class="ct.filter ? 'TagV2': 'TagV3'"
                v-for="ct in singleTags"
                :key="ct.name"
                :isClickable="true"
                :isActive="ct.filter"
                v-on:click="toggleFilter(ct)"
              />
            </div>
            <details v-for="group in sortedGroups" :open="getGroupTags(group).some(({filter}) => filter)" class="accordion">
              <summary>{{ group }}:</summary>
              <Tag
                :name="ct.name"
                :class="ct.filter ? 'TagV2': 'TagV3'"
                v-for="ct in getGroupTags(group)"
                :key="ct.name"
                :isClickable="true"
                :isActive="ct.filter"
                v-on:click="toggleFilter(ct)"
              />
            </details>
          </div>
        </div>
        <div class="MembersPageV2--Search">
          <div class="MembersPageV2--Header">
            <div>
                <CommunityButton v-on:click="showAllTags()">
                {{$t('show_community_tags_list')}}</CommunityButton>
                <CommunityButton v-on:click="openTagsModal()">{{ $t('add_tags_user') }}</CommunityButton>
              </div>
            </div>
          <SearchBar
            class="SearchBarV2"
            :placeholder="$t('find_by_name_or_tags')"
            :autocompleteListItems="allTagsNames"
            v-on:item-selected="handleItemSelected"
          />
          <div class="MembersPageV2--Tags" v-if="isClient">
            <Tag
              :name="t.name"
              class="TagV2"
              v-for="(t, i) in selectedFilters"
              :isClickable="true"
              :isActive="true"
              v-on:click="toggleFilter(t)"
            />
          </div>
        </div>
        <div class="MembersPageV2--Count">
          <div class="MembersPageV2--Members">
            <p>
              {{
                $t("members_show_of", {
                  membersNum: members.length,
                  membersTotal: totalMembers
                })
              }}
            </p>
            <p class="TotalMembers">
              {{ $t("members_show_total", {membersTotal: communityMembersCount}) }}
            </p>
          </div>
          <CommunityButton
            class="delete"
            v-on:click="deleteAllMembers()"
            type="button"
            name="button"
            ><i class="fas fa-user"></i>{{$t('members_remove_all',{count:members.length})}}</CommunityButton>
          <CommunityButton
            class="invite"
            v-on:click="resendInvitation()"
            type="button"
            name="button"
            ><i class="fas fa-envelope"></i>{{$t('members_resend_all',{count:members.length})}}</CommunityButton>
          <div class="MembersPageV2--Tabs">
            <p :class="{ active: selectedTab == 0 }" v-on:click="changeTab(0)">
              {{ $t("show_all") }}
            </p>
            <p :class="{ active: selectedTab == 1 }" v-on:click="changeTab(1)">
              {{$t('members_show_inactive')}}
            </p>
          </div>
        </div>
        <Separator class="MembersPageV2--TabsSeparator" />
        <div class="MembersPageV2--MembersContainer">
          <div
            class="MembersPageV2--List"
            :class="{ userSelected: selectedMember != false, userEditing: editingMember != false }"
          >
            <div
              class="MembersPageV2--NoInactives"
              v-show="selectedTab == 1 && members.length == 0"
            >
              {{ $t("members_no_inactive") }}
            </div>
            <CommunityUser
              :hideGamification="communityInfo.hideGamification"
              v-on:click.native="showMember(m)"
              :member="m"
              :key="m.identifier"
              v-for="(m, i) in this.members"
              :allowDelete="isCommunityMainAdmin"
              :allowRemind="false"
              v-on:delete="deleteMember(m)"
            />
          </div>
          <div class="MemberBox MemberSelected" v-if="selectedMember != false">
            <div class="MemberBox--Actions">
              <p
                class="delete"
                v-on:click="deleteMember(selectedMember)"
                v-if="isCommunityMainAdmin"
              >
                {{ $t("action_delete") }}
              </p>
              <p
                class="edit"
                v-on:click="showMemberEdition(selectedMember)"
                v-if="isCommunityAdmin"
              >
                {{ $t("action_edit") }}
              </p>
              <p class="close" v-on:click="hideMember()">
                {{ $t("action_close") }}
              </p>
            </div>
            <div class="MemberSelected--Info">
              <div
                class="MemberSelected--Image"
                :style="{
                  backgroundImage: selectedMember.picture ? 'url(\'' + selectedMember.picture + '\')' : 'url(\'https://upload.wikimedia.org/wikipedia/commons/c/cd/Portrait_Placeholder_Square.png\')',
                }"
              >
                <div
                  class="MemberSelected--Labs"
                  v-if="!communityInfo.hideGamification"
                >
                  {{ selectedMember.points }}
                </div>
              </div>
              <div class="MemberSelected--Segmentation">
                <h1>{{ selectedMember.nickname }}</h1>
                <div class="MemberSelected--SegmentationInfo">
                  <p v-if="selectedMember.age">
                    {{ $t("age", { count: selectedMember.age }) }}
                  </p>
                  <span v-if="selectedMember.gender"></span>
                  <p v-if="selectedMember.gender">
                    {{ $t("gender_" + selectedMember.gender) }}
                  </p>
                  <span
                    v-if="
                      (selectedMember.province && selectedMember.province.text) || selectedMember.geographicZone
                    "
                  ></span>
                  <p v-if="selectedMember.province && selectedMember.province.text">
                    {{
                      $t(''+selectedMember.province.text)
                    }}
                  </p>
                  <p
                    v-if="
                      selectedMember.geographicZone &&
                      (!selectedMember.province || selectedMember.province.text == undefined ||
                        selectedMember.province.text == null)
                    "
                  >
                    {{ selectedMember.geographicZone }}
                  </p>
                </div>
                <Separator />
                <div class="MemberSelected--SegmentationInfo">
                  <p>ID: {{ selectedMember.identifier }}</p>
                </div>
              </div>
            </div>
            <div class="MemberSelected--ExternalActions">
              <p v-on:click="handleOpenChat(selectedMember)">
                <i class="fa fa-comments"></i> {{ $t("chat") }}
              </p>
              <p v-on:click="handleOpenEmailCustom(selectedMember)">
                <i class="far fa-envelope"></i> {{ $t("custom_mail_send") }}
              </p>
            </div>
            <div class="MemberSelected--Tags" v-if="memberTotalTags.length > 0 && memberTotalTags[0].length > 0">
              <Tag :name="t" class="TagV2" v-for="t in memberTotalTags" /> 
            </div>
            <div
              class="MemberSelected--Participated"
              v-if="selectedMember.activitiesParticipated.length > 0"
            >
            <h2>{{$t('members_participated_activities')}}</h2>
              <Separator />
              <div class="MemberSelected--ScrollDiv">
                <div
                  class="MemberSelected--ParticipatedActivity"
                  v-for="act in selectedMember.activitiesParticipated"
                >
                  <div class="">
                    <img
                      v-if="act.type == 'WAT_SURVEY'"
                      src="@/assets/img/activities/survey-icon.svg"
                    />
                    <img
                      v-if="act.type == 'WAT_CHAT'"
                      src="@/assets/img/activities/chat-icon.svg"
                    />
                    <img
                      v-if="act.type == 'WAT_OPINION'"
                      src="@/assets/img/activities/opinion-icon.svg"
                    />
                    <img
                      v-if="act.type == 'WAT_TASK'"
                      src="@/assets/img/activities/task-icon.svg"
                    />
                    <img
                      v-if="act.type == 'WAT_POLL'"
                      src="@/assets/img/activities/poll-icon.svg"
                    />
                    <img
                      v-if="act.type == 'VIDEOCHAT'"
                      src="@/assets/img/activities/video-icon.svg"
                    />
                    <img
                      v-if="act.type == 'EL_HEATMAP'"
                      src="@/assets/img/activities/heatmap-icon.svg"
                    />
                  </div>
                  <p>{{ act.title }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="MemberBox MemberEdit" v-if="editingMember != false">
            <div class="MemberBox--Actions">
              <p class="close" v-on:click="hideMember()">
                {{ $t("action_close") }}
              </p>
            </div>
            <div class="MemberEdit--Form">
              <div class="MemberEdit--Fields">
                <a-tooltip >
                    <template #title>{{$t('nickname_rgpd_warning')}}</template>
                <CommunityInput
                  ref="nickname"
                  type="text"
                  :placeholder="$t('profile_data_nickname')"
                  v-model="editingMember.nickname"
                  :required="true"
                  :minLength="3"
                />
                </a-tooltip>

                <CommunityInput
                  ref="name"
                  type="text"
                  :placeholder="$t('name')"
                  v-model="editingMember.name"
                />
                <CommunityInput
                  ref="surname"
                  type="text"
                  :placeholder="$t('surname')"
                  v-model="editingMember.surname"
                />
                <CommunityInput
                  ref="email"
                  type="email"
                  :placeholder="$t('profile_personalization_email')"
                  v-model="editingMember.email"
                  :required="true"
                  v-show="
                    isCommunityMainAdmin ||
                    editingMember.identifier == undefined ||
                    editingMember.identifier == null
                  "
                />
                <CommunityInput
                  ref="bornDate"
                  :placeholder="$t('profile_personalization_borndate')"
                  v-model="editingMember.bornDate"
                  :lang="communityInfo.language ? communityInfo.language : 'es'"
                  type="date"
                  format="dd-MM-yyyy"
                  :yearRange="validBornDates"
                />
                <CommunityInput
                  ref="age"
                  type="number"
                  :placeholder="$t('profile_personalization_age')"
                  v-model="editingMember.age"
                  :required="false"
                />
                <select-input
                  id="gender"
                  :selected="String(editingMember.gender)"
                  :value="[
                    { id: 'M', value: $t('gender_M') },
                    { id: 'W', value: $t('gender_W') },
                  ]"
                  @change="handleChange"
                />
                <select-input
                  id="province"
                  :selected="String(editingMember.province.id)"
                  :value="provinces"
                  @change="handleChange"
                  :emptyVal="true"
                  :emptyValName="$t('form_select_province')"
                />
                <CommunityInput
                  ref="geographicZone"
                  type="text"
                  :placeholder="$t('profile_personalization_geographicZone')"
                  v-model="editingMember.geographicZone"
                />
                <div style="position: relative;">
                  <CommunityTagInput
                    ref="tags"
                    :placeholder="$t('form_add_tags')"
                    v-model="editingMember.tags"
                  />
                  <div style="position: absolute; top: 2px; right: 3px;">
                    <a-tooltip class="info-icon" placement="left">
                      <template #title>{{ $t('group_tags_tooltip') }}</template>
                      <span>
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </a-tooltip>
                  </div>
                </div>

                <div style="position: relative;">
                  <CommunityTagInput
                    type="text"
                    ref="tags"
                    :placeholder="$t('form_add_tags_public')"
                    v-model="editingMember.publicTags"
                  />
                  <div style="position: absolute; top: 2px; right: 3px;">
                    <a-tooltip class="info-icon" placement="left">
                      <template #title>{{ $t('group_tags_tooltip') }}</template>
                      <span>
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="MemberEdit--Actions">
              <CommunityButton
                v-if="!editingMember.identifier"
                v-on:click="createMember()"
                >{{$t('members_add_new')}}</CommunityButton
              >
              <CommunityButton
                v-if="editingMember.identifier"
                v-on:click="createMember()"
                >{{$t('action_save')}}</CommunityButton
              >
            </div>
          </div>
        </div>
      </div>
      <CommunityStatsPage v-show="membersTab == 1" />
      <div v-show="membersTab == 2" class="MembersRanking">
        <div class="MembersPageV2--Header">
          <div>
            <CommunityButton v-if="!communityInfo.hideGamification" v-on:click="setRanking('byParticipations')">{{
              $t("ranking_by_participations")
            }}</CommunityButton>
        <CommunityButton v-if="!communityInfo.hideGamification" v-on:click="setRanking('')">{{
              $t("ranking_by_points")
            }}</CommunityButton>
          </div>

        </div>
        <div class="MembersRanking--Top">
          <template v-for="(m, i) in ranking">
            <div class="MembersRanking--MemberTop" v-if="i <= 2">
              <div class="MembersRanking--RankingNumTop">
                {{ i + 1 }}
              </div>
              <CommunityUser
                class="MembersRanking--UserTop"
                :hideGamification="communityInfo.hideGamification"
                :member="m"
                :allowDelete="false"
                :allowRemind="false"
              />
            </div>
          </template>
        </div>
        <div class="MembersRanking--Members">
          <template v-for="(m, i) in ranking">
  <div class="MembersRanking--Member" v-if="i > 2">
    <div class="MembersRanking--RankingNum">
      {{ i + 1 }}
    </div>
    <CommunityUser
      class="MembersRanking--User"
      :hideGamification="communityInfo.hideGamification"
      :member="m"
      :allowDelete="false"
      :allowRemind="false"
    />
  </div>
</template>
        </div>
      </div>
    </BoxShadow>
    <Modal class="DeleteUserModal" :show="showDeleteUserModal">
      <template v-slot:content>
        <div class="DeleteUserModal__Content">
          <h4>{{ $t("members_delete_from_email_label") }}</h4>
          <input
            type="text"
            v-model="emailDeleteMember"
            placeholder="ejemplo@example.com"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="DeleteUserModal__Footer">
          <CommunityButton
            class="CommunityButton"
            :color="'gray'"
            v-on:click="showDeleteUserModal = false"
            >{{ $t("action_close") }}</CommunityButton
          >
          <CommunityButton
            class="CommunityButton"
            :color="theme.buttonColor"
            v-on:click="deleteMemberByEmail()"
            >{{ $t("member_delete") }}</CommunityButton
          >
        </div>
      </template>
    </Modal>
    <Modal class="LoadUsersModal" :show="showLoadUsersModal">
      <div slot="content">
        <div class="LoadUsersModal__container">
          <div class="LoadUsersModal__Info">
            <h1>{{ $t("members_upload_guide_title") }}</h1>
            <p>{{ $t("members_upload_guide_step1") }}</p>
            <p>{{ $t("members_upload_guide_step2") }}</p>
            <p>{{ $t("members_upload_guide_step3") }}</p>
          </div>
          <div class="LoadUsersModal__Actions">
            <CommunityButton
              :color="theme.buttonColor"
              v-on:click="downloadDemoExcel"
              >{{ $t("members_upload_guide_sample_excel") }}</CommunityButton
            >
            <div>
              <Dropzone
                class="LoadUsersModal__Uploader"
                ref="usersUploader"
                v-if="!isFileUploaded"
                :uploadUrl="usersUploader.path"
                :uploadHeaders="usersUploader.headers"
                :uploadText="$t('members_upload_guide_excel_upload')"
                :maxFilesize="10"
                :maxFiles="1"
                acceptedFiles=".xls,.xlsx"
                v-on:file-uploaded="handleUploadedFileEvent"
                v-on:file-added="handleAddedFileEvent"
                v-on:file-removed="handleRemovedFileEvent"
                v-on:file-error="handleError"
                :autoUpload="false"
              />
              <!-- acceptedFiles=".xls,.xlsx,.csv" -->
              <h3 v-else>{{ $t("members_upload_success") }}</h3>
              <h3
                class="LoadUsersModal__Error"
                v-if="!isFileUploaded && fileUploaderError"
              >
                {{ fileUploaderError }}
              </h3>
            </div>
            <CommunityButton
              :color="theme.buttonColor"
              v-on:click="handleUpload"
              :disabled="isFileUploading || !isFileAdded"
              v-if="!isFileUploaded"
              >{{ $t("action_load") }}</CommunityButton
            >
          </div>
        </div>
      </div>
      <div class="LoadUsersModal__Footer" slot="footer">
        <CommunityButton
          :color="theme.buttonColor"
          v-on:click="handleCloseLoadUsersModal"
          :disabled="isFileUploading"
          >{{ $t("action_close") }}</CommunityButton
        >
      </div>
    </Modal>
    <Modal class="AddTagsModal" :show="showTagsModal" :title="$t('community_action_add_tag')">
      <template v-slot:content>
        <div class="AddTagsModal--Content">
          <div>
            <label style="margin-right: .2rem;">{{ $t('tag') }}</label>
            <a-tooltip class="info-icon" placement="right">
              <template #title>{{ $t('group_tags_tooltip') }}</template>
              <span>
                <i class="fa fa-info-circle"></i>
              </span>
            </a-tooltip>
            <CommunityInput type="text" :required="true" v-model="usersTagName"/>
          </div>
          <div>
            <label>{{ $t('community_nick_or_id') }}</label>
            <CommunityInput type="textarea" :required="true" v-model="usersTagIds"/>
          </div>
          <SwitchInput class="SwitchInput--Small" v-model="isPublicTag" :text="$t('community_tag_public')"/>
        </div>
      </template>
      <template v-slot:footer>
        <CommunityButton
          :color="theme.buttonColor"
          v-on:click="addTagToMembers()"
          >{{ $t("community_action_add_tag") }}</CommunityButton>
        <CommunityButton
          :color="theme.buttonColor"
          v-on:click="showTagsModal = false"
          >{{ $t("action_close") }}</CommunityButton>
      </template>
    </Modal>
  </GradientBackLayout>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from "vuex";
import store from "@/store";
import {
  RESEND_INVITATION_MEMBERS,
  DELETE_ALL_MEMBERS,
  UPDATE_MEMBER,
  ADD_MEMBER,
  COMMUNITY_FETCH_MEMBERS_INACTIVE,
  FETCH_MEMBER_INFO,
  COMMUNITY_FETCH_MEMBERS,
  COMMUNITY_DELETE_MEMBER_EMAIL,
  COMMUNITY_FETCH_MEMBERS_EXCEL,
  COMMUNITY_FETCH_DEMO_EXCEL,
  COMMUNITY_FETCH_MEMBERS_RANKING,
  COMMUNITY_FETCH_MEMBERS_RANKING_PARTICIPATIONS,
  COMMUNITY_FETCH_MEMBERS_STATISTICS
} from "@/store/community/members.module";
import {
  COMMUNITY_FETCH_INFO,
  COMMUNITY_FETCH_STATUS,
} from "@/store/community/info.module";
import { COMMUNITY_FETCH_TAGS, COMMUNITY_SET_MEMBERS_TAG } from "@/store/community/tags.module";
import MemberItem from "@/components/MemberItem.vue";
import Tabs from "~/components/Tabs";
import Tag from "~/components/Tag";
import Modal from "~/components/Modal";
import CommunityButton from "@/components/CommunityButton";
import Dropzone from "~/components/Dropzone";
import { filter } from "lodash";
import ApiService from "@/services/api.service";
import $ from "jquery";
import FileSaver from "file-saver";
import GradientBackLayout from "@/layouts/GradientBackLayout";
import BoxShadow from "@/components/BoxShadow.vue";
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import Separator from "@/components/Separator";
import CommunityUser from "@/components/CommunityUser";
import SelectInput from "@/components/SelectInput.vue";
import CommunityInput from "@/components/CommunityInput.vue";
import activitySVG from '~/components/ActivitySVG/activitySVG.vue'
import PhotoUploader from "@/components/PhotoUploader.vue";
import CommunityStatsPage from "@/components/CommunityStatsPage.vue";
import CommunityTagInput from '~/components/CommunityTagInput/CommunityTagInput.vue';
import SwitchInput from "~/components/SwitchInput/SwitchInput";

export default {
  name: "MembersPage",

  components: {
    MemberItem,
    Tabs,
    Tag,
    Modal,
    CommunityButton,
    Dropzone,
    GradientBackLayout,
    BoxShadow,
    SearchBar,
    Separator,
    CommunityUser,
    SelectInput,
    CommunityInput,
    activitySVG,
    PhotoUploader,
    CommunityStatsPage,
    CommunityTagInput,
    SwitchInput
  },

  computed: {
    ...mapGetters([
      "communityTags",
      "communityPublicTags",
      "communityTagsGroups",
      "communityId",
      "isClient",
      "isUser",
      "isCommunityMainAdmin",
      "isCommunityAdmin",
      "provinces",
      "isModerator",
      "userProfile",
      "theme",
      "communityInfo",
      "onlineUsers",
      'communityMembersCount'
    ]),
    usersUploader() {
      return {
        path:
          process.env.VUE_APP_API_URL +
          "/experienceLabs/community/" +
          this.communityId +
          "/usersExcel",
        headers: ApiService.getConfig().headers,
      };
    },
    validBornDates(){
      const date = new Date();
      const year = date.getFullYear();
      const limit_old = year - 100;
      const limit_young = year - 16;
      return [limit_old, limit_young]
    },
    allTagsNames() {
      return this.allTagsVersion2.map(({name}) => name)
    },
    singleTags() {
      return this.allTagsVersion2.filter(({name}) => !name.includes(':'))
    },
    groupTags() {
      return this.allTagsVersion2.filter(({name}) => name.includes(':'))
    },
    selectedFilters() {
      return this.allTagsVersion2.filter(({filter}) => filter)
    },
    selectedFiltersNames() {
      return this.selectedFilters.map(({name}) => name)
    },
    sortedGroups() {
      return this.communityTagsGroups.sort((a, b) => a.localeCompare(b, 'es', { sensitivity: 'base' }))
    }
    // selectedGroups() {
    //   return this.filterTags.filter(tag => this.communityTagsGroups.includes(tag))
    // },
  },
  watch: {
    onlineUsers: function(onlineUsers) {
      for (let memberIndex in this.members) {
        this.members[memberIndex].online = this.onlineUsers.testersOnlineCondensed.includes(this.members[memberIndex].identifier)
      }
    },
    'editingMember.nickname': function(newVal) {
      if (newVal) this.editingMember.nickname = newVal.trim();
    },
    communityTags: 'updateAllTags',
    communityPublicTags: 'updateAllTags',
    communityTagsGroups: 'updateAllTags',
  },
  data: () => ({
    // filterTags: [],
    filterNickname: "",
    filterIdentifier: 0,
    members: [],
    ranking: [],
    selectedTab: 0,
    membersTab: 0,
    selectedMember: false,
    editingMember: false,
    showDeleteUserModal: false,
    emailDeleteMember: "",
    showLoadUsersModal: false,
    isFileAdded: false,
    isFileUploading: false,
    isFileUploaded: false,
    fileUploaderError: "",
    email: "",
    emails: [],
    rankingType: "byParticipations",
    page:0,
    pageSize:50,
    isLoadingMembers: false,
    memberTotalTags: [],
    showTagsList: false,
    // allTags: [],
    allTagsVersion2: [],
    usersTagIds: '',
    usersTagName:'',
    showTagsModal:false,
    isPublicTag: false,
    totalMembers: 0,
    backupFilter: {
      type:"",
      text:""
    },
    loadingTags: false,
    currentRequest: null,
    debounceTimeout: null
  }),

  async mounted() {
    document.getElementsByClassName('MembersPageV2')[0].addEventListener('scroll', this.pageScroll)
    this.fetchTags();
    this.fetchMembers();
    // this.allTags = this.communityTags.concat(this.communityPublicTags);
    // this.updateAllTags()
    await this.fetchRanking(this.rankingType);
  },
  methods: {
    goToEditLabs() {
      this.$router.push({ name: "customLabs" });
    },
    goToUsersLoad() {
      this.$router.push({ name: "members.preview" });
    },
    openTagsModal() {
      this.showTagsModal = true
    },
    validateTagName(tag){
      if(tag.trim() == "") return false
      const regexSpecialChar = /[!@#$%^&*()+{}\[\];<>,.?~\\/]/;
      return !regexSpecialChar.test(tag)
    },
    validateTagUsers(users){
      if(users == "") return false
      return true
    },
    validateGroupTagName(tag) {
      const splitedTag = tag.split(':')
      const validLength = splitedTag.length <= 2
      const hasSomeEmptyPart = splitedTag.some(part => !part)

      return validLength && !hasSomeEmptyPart
    },
      async addTagToMembers() {
          const validTag = this.validateTagName(this.usersTagName);
          const validUsers = this.validateTagUsers(this.usersTagIds);

          if (!validTag || !validUsers) {
              Swal.fire({
                  text: this.$t("tag_for_members_error"),
                  icon: "error",
                  customClass: { popup: "swal-customError" },
              });
              return;
          }

          const validGroupTag = this.validateGroupTagName(this.usersTagName);

          if (!validGroupTag) {
              Swal.fire({
                  text: this.$t("group_tag_error"),
                  icon: "error",
                  customClass: { popup: "swal-customError" },
              });
              return;
          }

          const users = this.usersTagIds.split(',').filter(user => !!user.trim());
          let isIntegerUsers = true;
          for (let u of users) {
              if (isNaN(parseInt(u))) isIntegerUsers = false;
          }

          this.loadingTags = true;

          try {
              const data = await store.dispatch(COMMUNITY_SET_MEMBERS_TAG, {
                  userIds: isIntegerUsers ? users : null,
                  userNicks: isIntegerUsers ? null : users,
                  tagName: this.usersTagName,
                  isPublic: this.isPublicTag,
              });

              if (data && (data.failedUserIds || data.failedUserNicks || data.duplicateUserNicks || data.duplicateTesterIds || data.tagError)) {
                  this.loadingTags = false;
                  this.handleFailedResponse(data);
              } else {
                  this.afterAddNewTag();
                  this.usersTagIds = '';
                  this.usersTagName = '';
                  this.isPublicTag = false;
                  this.showTagsModal = false;
                  this.loadingTags = false;
                  Swal.fire({
                      icon: "success",
                      text: this.$t('new_tag_for_members_success')
                  });
              }
          } catch (error) {
              console.error("Error en addTagToMembers:", error);
              this.loadingTags = false;
              if (error && (error.failedUserIds || error.failedUserNicks || error.duplicateTesterIds || error.duplicateUserNicks )) {
                  this.handleFailedResponse(error);
              } else {
                  Swal.fire({
                      icon: "error",
                      text: this.$t('new_tag_for_members_error')
                  });
              }
          }
      },

      handleFailedResponse(data) {
          if (data && (data.failedUserIds || data.failedUserNicks || data.duplicateTesterIds || data.duplicateUserNicks || data.tagError)) {
              this.loadingTags = false;

              let errorMessage = '';
              let errorFooterMessage = ''

              let failedIds = [];
              let failedNicks = [];

              if (data.failedUserIds && data.failedUserIds.length > 0) {
                  errorMessage += `${this.$t('failed_user_ids', { ids: data.failedUserIds.join(', ') })}\n`;
                  failedIds = failedIds.concat(data.failedUserIds);
              }

              if (data.failedUserNicks && data.failedUserNicks.length > 0) {
                  errorMessage += `${this.$t('failed_user_nicknames', { nicks: data.failedUserNicks.join(', ') })}\n`;
                  failedNicks = failedNicks.concat(data.failedUserNicks);
              }

              if (data.duplicateTesterIds && data.duplicateTesterIds.length > 0) {
                  errorMessage += `${this.$t('duplicate_user_ids', { ids: data.duplicateTesterIds.join(', ') })}\n`;
                  errorFooterMessage = this.$t('duplicates_deleted')
                  failedIds = failedIds.concat(data.duplicateTesterIds);
              }

              if (data.duplicateUserNicks && data.duplicateUserNicks.length > 0) {
                  errorMessage += `${this.$t('duplicate_user_nicknames', { nicks: data.duplicateUserNicks.join(', ') })}\n`;
                  errorFooterMessage = this.$t('duplicates_deleted')
                  failedNicks = failedNicks.concat(data.duplicateUserNicks);
              }

              if (failedIds.length > 0 || failedNicks.length > 0) {
                this.usersTagIds = [...new Set(this.usersTagIds.split(',').map(id => id.trim()))].join(',');
              }

              if(data.tagError) {
                errorMessage += this.$t('tag_error')
              }

              Swal.fire({
                  icon: "error",
                  text: errorMessage,
                  footer: errorFooterMessage && `<small>* ${errorFooterMessage}</small>`,
                  customClass: { popup: "swal-customError" },
              });
          } else {
              this.afterAddNewTag();
              this.usersTagIds = '';
              this.usersTagName = '';
              this.isPublicTag = false;
              this.showTagsModal = false;
              this.loadingTags = false;
              Swal.fire({
                  icon: "success",
                  text: this.$t('new_tag_for_members_success')
              });
          }
      },

    async afterAddNewTag(){
      await this.fetchTags();
      // this.allTags = this.communityTags.concat(this.communityPublicTags);
      this.fetchMembers();
    },
    deleteMemberByEmail() {
      store
        .dispatch(COMMUNITY_DELETE_MEMBER_EMAIL, {
          email: this.emailDeleteMember,
        })
        .then(async (data) => {
          if (data.status != null)
            Swal.fire({
              text:this.$t("members_member_not_existing"),
              icon:"error",
              customClass:{popup:"swal-customError"},
            })
          else
            Swal.fire({
              text:this.$t("msg_member_deleted"),
              icon:"success",
              customClass:{popup:"swal-customSuccess"},
            })
        });
    },
    async fetchMembersExcel() {
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(COMMUNITY_FETCH_MEMBERS_EXCEL, {}).then((data) => {
              var file = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              FileSaver.saveAs(
                file,
                vm.$t("community") + " " + vm.communityId + ".xlsx"
              );
            });
          })();
        }
      })
    },
    changeTab(tabNum) {
      this.selectedTab = tabNum;
      this.fetchMembers();
    },
    async fetchRanking(type) {
      if(type == "byParticipations"){
        await store.dispatch(COMMUNITY_FETCH_MEMBERS_RANKING_PARTICIPATIONS).then((data) => {
          this.ranking = data;
        });
      }else{
        await store.dispatch(COMMUNITY_FETCH_MEMBERS_RANKING).then((data) => {
          this.ranking = data;
        });
      }
    },
    pageScroll(e){
      if(this.isLoadingMembers) return
      const { scrollTop, scrollHeight, clientHeight } = document.getElementsByClassName('MembersPageV2')[0];
      if (scrollTop + clientHeight >= scrollHeight - 5 && this.selectedTab == 0){
        if(this.page == 0 && this.pageSize > 10){
          this.page = this.pageSize / 10
          this.pageSize = 10
        }
        else this.page++
        this.fetchMembers(this.backupFilter, true)
      }
    },
    handleItemSelected(event) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.fetchMembers(event);
      }, 500);
    },
    async fetchMembers(filter, isPageScroll) {
      if (filter == undefined || filter.text == "") {
        this.filterNickname = "";
        this.filterIdentifier = 0;
        this.email="";
      }
      if (filter != undefined && filter.text != "" && filter.type == "filter") {
        // this.filterTags.push(filter.text);
        // this.allTags = this.allTags.filter(tag => tag !== filter.text);
        this.allTagsVersion2.forEach(t => t.name === filter.text ? t.filter = true : null)
        this.filterNickname = "";
        this.filterIdentifier = 0;
        this.email="";
      }
      let publicTags = [];
      let tags = [];
      for (let t of this.communityTags) {
        // if (this.filterTags.includes(t)) tags.push(t);
        if (this.selectedFiltersNames.includes(t)) tags.push(t);
      }
      for (let t of this.communityPublicTags) {
        // if (this.filterTags.includes(t)) publicTags.push(t);
        if (this.selectedFiltersNames.includes(t)) publicTags.push(t);
      }
      if (filter != undefined && filter.type == "nickname"){
         this.filterNickname = filter.text;
         this.filterIdentifier = 0;
         this.email="";
      }
      if (filter != undefined && filter.type == "identifier"){
         this.filterNickname = "";
         this.filterIdentifier = parseInt(filter.text, 10);
         this.email = "";
      }
      if (filter != undefined && filter.type == "email"){
        this.email = filter.text;
        this.filterNickname = "";
        this.filterIdentifier = 0;
      }
      if(!isPageScroll && this.selectedTab == 0 && this.page > 0){
        this.pageSize = (this.page + 1) * this.pageSize
        this.page = 0
      }
      if (filter != undefined && filter.type != "filter") {
        this.backupFilter = filter;
      } else {
        this.backupFilter = null;
      }
      this.isLoadingMembers = true

      const request = store
        .dispatch(
          this.selectedTab == 0
            ? COMMUNITY_FETCH_MEMBERS
            : COMMUNITY_FETCH_MEMBERS_INACTIVE,
          {
            order: "recent",
            notSetCommunityMembers: true,
            filter: tags.join(","),
            publicFilter: publicTags.join(","),
            nickname: this.filterNickname != "" ? this.filterNickname : undefined,
            identifier: this.filterIdentifier > 0 ? this.filterIdentifier : undefined,
            email: this.email != "" ? this.email : undefined,
            page:this.page,
            pageSize: this.pageSize
          }
        );

      this.currentRequest = request;
      const data = await request;
      if (this.currentRequest !== request) return;

      this.isLoadingMembers = false
      if (data.totalTesters != undefined && data.communityMembers != undefined) {
        if(isPageScroll) this.members = this.members.concat(data.communityMembers)
        else this.members = data.communityMembers;
        for (let m of data.communityMembers) {
          m.online = this.onlineUsers.testersOnlineCondensed.includes(m.identifier)
        }
        this.totalMembers = data.totalTesters;
      } else {
        this.members = [];
        this.totalMembers = 0;
      }

    },
    async fetchTags() {
      await store.dispatch(COMMUNITY_FETCH_TAGS)
      // await store.dispatch(COMMUNITY_FETCH_TAGS).then((data) => {
      //   if (data.publicTags == undefined && data.tags == undefined)
      //     this.tags = [];
      //   else if (data.publicTags == undefined) this.tags = data.tags;
      //   else if (data.tags == undefined) this.tags = data.publicTags;
      //   else {
      //     this.tags = data.publicTags.concat(data.tags);
      //   }
      // });
    },
    removeFilterTag(i, t) {
      // this.filterTags.splice(i, 1);
      // this.filterTags = this.filterTags.filter(item => item !== t);

      if(!this.communityTagsGroups.includes(t)) {
        // this.allTags.push(t);
      }
      this.fetchMembers();
    },
    async showMember(member) {
      await store
        .dispatch(FETCH_MEMBER_INFO, {
          userId: member.identifier
        })
        .then((data) => {
          this.selectedMember = data;
          this.memberTotalTags = []
          for (let tagIndex in this.selectedMember.tags) {
            this.memberTotalTags.push(this.selectedMember.tags[tagIndex])
          }
          for (let tagIndex in this.selectedMember.publicTags) {
            this.memberTotalTags.push(this.selectedMember.publicTags[tagIndex])
          }
          this.editingMember = false;
        });
    },
    hideMember() {
      this.selectedMember = false;
      this.editingMember = false;
    },
    deleteMember(member) {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t("msg_member_be_deleted"),
        icon: 'warning',
        cancelButtonText: vm.$t("action_cancel"),
        confirmButtonText: vm.$t("msg_confirm_delete"),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(DELETE_ALL_MEMBERS, {
              userIds: [member.identifier],
            })
            .then(async (data) => {
              await store.dispatch(COMMUNITY_FETCH_INFO);
              this.fetchMembers();
              Swal.fire(this.$t("msg_member_deleted2"));
            })
            .catch(({ response }) => {
              Swal.fire({
                title:vm.$t('msg_error_ocurred'),
                icon:"error",
                customClass:{popup:"swal-customError"},
              })
            });
        }
      })
    },
    deleteAllMembers() {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t('msg_users_deleted'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('msg_confirm_delete'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(DELETE_ALL_MEMBERS, {
              userIds: vm.members.map((m) => m.identifier),
            })
            .then(async (data) => {
              await store.dispatch(COMMUNITY_FETCH_INFO);
              vm.fetchMembers();
              Swal.fire("Miembros eliminados");
            })
            .catch(({ response }) => {
              Swal.fire({
                title:vm.$t('msg_error_ocurred'),
                icon:"error",
                customClass:{popup:"swal-customError"},
              })
            });
        }
      })
    },
    resendInvitation() {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t('msg_welcome_email'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('msg_agree'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(RESEND_INVITATION_MEMBERS, {
              userIds: vm.members.map((m) => m.identifier),
            })
            .then(async (data) => {
              Swal.fire(vm.$t('msg_welcome_email_sent'));
            })
            .catch(({ response }) => {
              Swal.fire({
                title:vm.$t('msg_error_ocurred'),
                icon:"error",
                customClass:{popup:"swal-customError"},
              })
            });
        }
      })
    },
    showMemberEdition(member) {
      this.selectedMember = false;
      this.editingMember = {
        identifier: member != undefined ? member.identifier : undefined,
        nickname: member != undefined ? member.nickname : "",
        name: member != undefined ? member.name : "",
        surname: member != undefined ? member.surname1 : "",
        email: member != undefined ? member.email : "",
        bornDate: member != undefined ? member.bornDate : "",
        age: member != undefined ? member.age : "",
        geographicZone: member != undefined ? member.geographicZone : "",
        gender: member != undefined ? member.gender : "W",
        province:
          member != undefined
            ? member.province != undefined
              ? member.province
              : { id: -1, elementId: -1, lovName: "provinces" }
            : { id: -1, elementId: -1, lovName: "provinces" },
        tags: member != undefined ? member.tags != undefined ? member.tags: [] : [],
        publicTags:
          member != undefined
            ? member.publicTags != undefined
              ? member.publicTags
              : [] : [],
      };
    },
    handleChange(value, id) {
      if (id == "province") {
        this.editingMember[id].id = value;
        this.editingMember[id].elementId = value;
      } else this.editingMember[id] = value;
    },
    handleOpenChat: function (member) {
      this.$router.push({
        name: "chat",
        params: { chatSelected: member.identifier },
      });
    },
    handleOpenEmailCustom: function (member) {
      this.$router.push({
        name: "email",
        params: { addedMembers: [member.identifier] },
      });
    },
    async createMember() {
      var vm = this;
      if (this.theme.afterLoginBypass) this.$refs.nickname.validate();
      this.$refs.name.validate();
      this.$refs.surname.validate();
      if (
        this.isCommunityMainAdmin ||
        this.editingMember.identifier == undefined ||
        this.editingMember.identifier == null
      )
        this.$refs.email.validate();
      else this.editingMember.email = null;
      this.$refs.bornDate.validateDate();
      if (
        (this.theme.afterLoginBypass && this.$refs.nickname.error) ||
        this.$refs.name.error ||
        this.$refs.surname.error ||
        this.$refs.email.error ||
        this.$refs.bornDate.error
      )
        return;
      if (this.editingMember.identifier) return this.updateMember();
      if (!this.theme.afterLoginBypass) this.editingMember.afterLoginComplete = false
      await store
        .dispatch(ADD_MEMBER, {
          data: this.editingMember,
        })
        .then(async (data) => {
          this.editingMember = false;
          Swal.fire({
            text:vm.$t('msg_user_created'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"},
          })
          await this.fetchTags();
          this.fetchMembers();
        })
        .catch(({ response }) => {
          if (response.data && response.data.duplicatedKey) {
            let errorMessage = ""
            switch (response.data.duplicatedKey) {
              case "nick":
              errorMessage = this.$t('error_already_user_exists')
                break;
              case "mail":
              errorMessage = this.$t('error_already_email_exists')
                break;
            
              default:
                break;
            }
            Swal.fire({
              title: errorMessage,
              icon:"error",
              customClass:{popup:"swal-customError"},
            })
          }else{
            Swal.fire({
              title:vm.$t('msg_error_ocurred'),
              text:vm.$t(response.data.payload),
              icon:"error",
              customClass:{popup:"swal-customError"},
            })
          }
        });
    },
    async updateMember() {
      var vm = this;
      await store
        .dispatch(UPDATE_MEMBER, {
          userId: this.editingMember.identifier,
          data: this.editingMember,
        })
        .then(async (data) => {
          this.editingMember = false;
          Swal.fire({
            text:vm.$t('msg_user_updated'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"},
          })
          await this.fetchTags();
          this.fetchMembers();
        })
        .catch(({ response }) => {
          if (response.data && response.data.duplicatedKey) {
            let errorMessage = ""
            switch (response.data.duplicatedKey) {
              case "nick":
              errorMessage = this.$t('error_already_user_exists')
                break;
              case "mail":
              errorMessage = this.$t('error_already_email_exists')
                break;
            
              default:
                break;
            }
            Swal.fire({
              title: errorMessage,
              icon:"error",
              customClass:{popup:"swal-customError"},
            })
          }else{
            Swal.fire({
              title:vm.$t('msg_error_ocurred'),
              icon:"error",
              customClass:{popup:"swal-customError"},
            })
          }         
        });
    },
    handleCloseLoadUsersModal() {
      this.fileUploaderError = "";
      this.showLoadUsersModal = false;
      this.isFileUploaded = false;
      this.isFileUploading = false;
    },
    handleUpload() {
      this.fileUploaderError = "";
      this.isFileUploading = true;
      this.$refs.usersUploader.processUpload();
    },
    handleError(file, message, xhr) {
      this.isFileUploading = false;
      this.fileUploaderError = message.payload;
    },
    handleAddedFileEvent() {
      this.isFileAdded = true;
    },
    handleRemovedFileEvent() {
      this.isFileAdded = false;
      this.isFileUploading = false;
    },
    async handleUploadedFileEvent() {
      await store.dispatch(COMMUNITY_FETCH_INFO);
      this.fetchMembers();
      this.isFileUploading = false;
      this.isFileUploaded = true;
    },
    downloadDemoExcel: async function () {
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          (async (data) => {
            await store.dispatch(COMMUNITY_FETCH_DEMO_EXCEL, {}).then((data) => {
              var file = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              FileSaver.saveAs(file, vm.$t("members_demo_file") + ".xlsx");
            });
          })();
        }
      })
    },
    async setRanking(type){
      this.rankingType = type;
      await this.fetchRanking(this.rankingType);
    },
    showAllTags() {
      this.showTagsList = !this.showTagsList;
    },
    addSelectedFilter(ct) {
      // this.filterTags.push(ct);
      // this.allTags = this.allTags.filter(item => item !== ct);
      this.fetchMembers();
    },
    toggleFilter(ct) {
      ct.filter = !ct.filter
      this.fetchMembers();
    },
    updateAllTags() {
      this.allTagsVersion2 = [...this.communityTags, ...this.communityPublicTags]
        .sort((a, b) => a.localeCompare(b, 'es', { sensitivity: 'base' }))
        .map(tag => ({ name: tag, filter: false }))
    },
    showTagListInfo() {
      var vm = this;
      Swal.fire({
        title: vm.$t('community_tags_list'),
        text: vm.$t('info_community_tags_list'),
        icon:"info"
      })
    },
    // filterMembersBySelectedGroup() {
    //   return this.members.filter((member) => {
    //     const isIncludedInTags = this.selectedGroups.some(
    //       (group) =>
    //         member.tags?.some((tag) => tag.includes(group)) ||
    //         member.publicTags?.some((tag) => tag.includes(group))
    //     );

    //     return isIncludedInTags;
    //   });
    // },
    getGroupTags(group) {
      // return this.allTags.filter(tag => tag.includes(':') && tag.split(':')[0] === group)
      return this.groupTags.filter(({ name }) => name.split(':')[0] === group)
    }
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

.LoadingIcon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 35, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: inherit;
}

.AddTagsModal{
  z-index: 990;
  &--Content{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  button{
    margin-right: 20px;
  }
}
.MembersPageV2 {
  &__Tabs {
    position: absolute;
    top: -32px;
    right: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    &--tab {
      background: #f6f6f6;
      cursor: pointer;
      display: flex;
      margin-right: 5px;
      padding: 7px 16px;
      font-size: 15px;
      letter-spacing: -0.01em;
      &:last-of-type {
        margin-right: 10px;
      }
      &.active {
        background: white;
      }
    }
  }
  &--Box {
    padding: 40px;
    display: flex;
    flex-direction: column;
  }
  &--Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
    &.no-margin {
      margin-bottom: 0;
    }
    button{
        background:var(--primary-color);
    }
    @media only screen and (max-width: 600px) {
      flex-direction:column;
    }
    div {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      button {
        margin-right: 10px;
        background-color: white !important;
        color: var(--accent-color) !important;
        margin-bottom: 10px;
        border: 1px solid #f2f2f2;
      }
    }
    button {
      font-size: 13px;
      background: var(--accent-color) !important;
    }
  }
  &--TagsList {
    background: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 6px;
    padding: 20px;
    width: 50%;
    z-index: 100;
    position: absolute;
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
  }
  &--Tags {
    max-height: 30dvh;
    overflow-y: scroll;
    margin-top: 10px;
    .Tag {
      margin: 2px;
    }

  }
  .accordion {
    padding: 5px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid #444;
    }

    summary {
      cursor: pointer;
      padding: 5px 10px;
      transition: background-color .5s;

      &:hover {
        background-color: #eee;
      }
    }
  }
  &--Count {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    position: relative;
    p {
      font-size: 17px;
      letter-spacing: -0.01em;
      color: #3e3e3e;
      margin-right: 10px;
    }
    .TotalMembers {
      color: #747474;
    }
    button {
      font-size: 13px;
      i {
        margin-right: 5px;
      }
      &.delete {
        background: linear-gradient(
          255.48deg,
          #820509 10.29%,
          #be0007 89.71%
        ) !important;
      }
      &.invite {
        margin-left: 10px;
        background: linear-gradient(
          255.48deg,
          #717171 10.29%,
          #a1a1a1 89.71%
        ) !important;
      }
    }
  }
  &--TabsSeparator {
    z-index: 1;
  }
  &--Tabs {
    position: absolute;
    right: 0;
    bottom: -11px;
    display: flex;
    cursor: pointer;
    p {
      background: #e1e1e1;
      box-shadow: inset 0px -2px 3px rgba(0, 0, 0, 0.04);
      font-size: 15px;
      letter-spacing: -0.01em;
      color: #3e3e3e;
      width: 103px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      &.active {
        color: var(--primary-color-text);
        background: #ffffff;
        border: 1px solid #e1e1e1;
        z-index: 2;
        border-bottom: none;
        box-shadow: none;
      }
    }
    @media (max-width: 1050px){
      position: relative;
      width: 100%;
      margin-top: 20px;
      padding: 1px;
      p{
        color: red;
      }
    }
  }
  &--MembersContainer {
    display: flex;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap:40px;
    }
    button{
        background:var(--accent-color)!important;
    }
  }
  &--SelectedMember {
    background: #ffffff;
    box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
    margin-left: 30px;
  }
  &--NoInactives {
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    margin: auto;
    font-size: 20px;
    font-weight: 800;
    border-bottom: 1px solid #d3d3d3;
    color: #3e3e3e;
    font-weight: 500;
    max-width: 315px;
  }
  &--List {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .CommunityUser {
      flex: 1 1 270px;
      margin-bottom: 14px;
      margin-right: 14px;
      cursor: pointer;
    }
    &.userSelected, &.userEditing {
      width: 50%;
      @media screen and (max-width: 1024px) {
       width: 100%;
       order:2;
      }
    }
  }
}
.MemberBox {
  box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
  padding: 25px;
  width: 100%;
  margin-left: 30px;
  height: fit-content;
  @media screen and (max-width: 1024px) {
    margin-left:0;
    }
  &--Actions {
    display: flex;
    justify-content: flex-end;
    p {
      font-size: 16px;
      letter-spacing: -0.01em;
      margin-left: 15px;
      cursor: pointer;
      &.delete {
        color: #e8060f;
      }
      &.edit {
        color: #f1952a;
      }
      &.close {
        color: #3e3e3e;
      }
    }
  }
}
.MemberSelected {
  &--ExternalActions {
    display: flex;
    margin-top: 26px;
    p {
      margin-right: 10px;
      cursor: pointer;
    }
    i {
      color: var(--primary-color);
    }
  }
  &--Info {
    display: flex;
  }
  &--Segmentation {
    h1 {
      font-size: 28px;
      letter-spacing: 0.01em;
      color: #3e3e3e;
    }
  }
  &--SegmentationInfo {
    display: flex;
    font-size: 19px;
    color: #747474;
    width: max-content;
    span {
      background: #747474;
      width: 1px;
      margin: 2px 5px;
    }
  }
  &--Image {
    width: 125px;
    height: 139px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-right: 25px;
    margin-top: -20px;
    border: 2px solid #ffffff;
  }
  &--Labs {
    background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
    border: 1px solid #ffffff;
    box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.11);
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    color: #ffffff;
    width: 75px;
    height: 32px;
    position: absolute;
    bottom: -16px;
    left: calc(50% - 37px);
  }
  &--Tags {
    background: #f5f5f5;
    padding: 10px 14px;
    margin-top: 26px;
  }
  &--ScrollDiv {
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--primary-color);
    }
  }
  &--Participated {
    margin-top: 26px;
    h2 {
      font-size: 17px;
      letter-spacing: -0.01em;
      color: #3e3e3e;
    }
  }
  &--ParticipatedActivity {
    margin-top: 30px;
    display: flex;
    align-items: center;
    div {
      width: 30px;
      height: 30px;
      margin-right: 18px;
      img {
        width: 100%;
      }
    }
    p {
      font-weight: bold;
      font-size: 18px;
      color: #3e3e3e;
    }
  }
}
.MemberEdit {
  &--Form {
    display: flex;
  }
  // &--Image{
  //   p{
  //     font-size: 13px;
  //     letter-spacing: 0.03em;
  //     color: #3E3E3E;
  //     margin-top: 5px;
  //   }
  // }
  // &--ImageBox{
  //   width: 125px;
  //   min-width: 125px;
  //   height: 137px;
  //   background: #F5F5F5;
  //   border: 1px solid #e4e4e4;
  //   margin-right: 15px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: center;
  // }
  &--Fields {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    .TextInput,
    .SelectInput {
      width: 100%;
      padding: 0;
    }
  }
  &--Actions {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
}
.DeleteUserModal {
  &__Content {
    font-size: 20px;
    input {
      margin-top: 10px;
      width: 80%;
      height: 40%;
      font-size: 16px;
    }
  }
  &__Footer {
    margin-top: 40px;
    .CommunityButton {
      margin-right: 10px;
    }
  }
}
.LoadUsersModal {
  button {
    margin: 10px 0;
  }
}
@media (max-width: 895px) {
  .MemberEdit--Form {
    flex-direction: column;
  }
}
</style>
<style lang="scss">
.MemberEdit--ImageBox svg {
  width: 30px;
  height: 30px;
}
.MembersRanking {
  &--Top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }
  &--Members {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    @media (max-width: 768px) {
      grid-template-columns: auto;
    }
  }
  &--User {
    width: 100%;
  }
  &--UserTop {
    height: 134px;
    background: white !important;
    &:before {
      left: 75px;
      top: 3px;
      z-index: 1;
      font-size: 20px !important;
      width: 50.23px !important;
      height: 25.16px !important;
    }
    .Avatar {
      width: 97px;
      height: 108px;
    }
    .CommunityUser--info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h1 {
        font-weight: bold;
        font-size: 20px;
      }
    }
    .CommunityUser--segmentation {
      font-size: 17px;
    }
  }
  &--MemberTop {
    position: relative;
    width: 100%;
    margin-left: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
      overflow: hidden;
    filter: drop-shadow(0px 1px 19px rgba(0, 0, 0, 0.1));
    &:first-of-type {
      margin-left: 0;
    }
  }
  &--Member {
    display: flex;
    width: 100%;
    box-sizing: border-box;
      overflow: hidden;
  }
  &--RankingNumTop {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    color: #ff4149;
    position: absolute;
    top: 11px;
    right: 7px;
    z-index: 1;
  }
  &--RankingNum {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    letter-spacing: -0.01em;
    color: #ff4149;
    width: 52px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>